.steps-container{
    margin-top: 20px !important;
    padding-left: 40px;
    padding-right: 40px;
    width: 48vw;
    max-width: 48vw;
}

.progess_bar
{
    background-color: #9F92F3;
    height: 4px;
    width: 78%;
    position: absolute;
    left: 50px;
    top: 16px;
    border-radius: 10px;
}

.progess_bar2{
    background-color: #9F92F3;
    height: 4px;
    width: 34%;
    /* position: absolute; */
    left: 17vw;
    top: 16px;
    border-radius: 10px;
}

.progess_bar_inactive{
    background-color: #D4D4D4;
    height: 4px;
    width: 78%;
    position: absolute;
    left: 50px;
    top: 16px;
    border-radius: 10px;
}