* {
  font-family: "Roboto";
}

.profile-connect {
  padding: 20px 20px;
  display: flex;
  justify-content: space-between;
}

.profile-section {
  padding: 20px 20px;
}

.profile_head1 {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #364141;
}

.profile_head2 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  color: #364141;
}

.social-links {
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.socials {
  display: flex;
  flex-direction: column;
  width: 25%;
  text-align: center;
  border-top: 10px solid #217bee !important;
  border-radius: 10px;
  padding: 5px !important;
}

.instagram {
  border-top: 10px solid #ed4c42 !important;
}

.twitter {
  border-top: 10px solid #1da1f2 !important;
}

.linkedin {
  border-top: 10px solid #0a66c2 !important;
}

.googlebusiness {
  border-top: 10px solid #4285f4 !important;
}

.pintestbusiness {
  border-top: 10px solid #b00404 !important;
}

.youtube {
  border-top: 10px solid #ff0000 !important;
}

.socials label {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  text-align: center;
  color: #364141;
}

.social-name {
  padding: 20px 10px;
}

.connect-btn {
  padding: 10px 10px;
  /* margin: 10px 10px; */
  background-color: transparent;
  border: 1px solid #364141;
  border-radius: 6px;
  width: 100%;
}

.social-logo {
  padding: 30px 10px;
}

.settings {
  width: 20px;
}

.msg-action {
  justify-content: right;
  display: flex;
  padding: 10px 150px;
}

.msg-action a {
  margin: 0 10px;
  text-decoration: none;
}

.notification-section {
  flex-direction: row;
  display: flex;
  border-left: 5px solid #ed4c42;
  padding: 10px 20px;
  align-items: center;
  width: 100%;
  margin: 8px 0px;
}

.notification-section i {
  margin-left: 10px;
  margin-right: 30px;
}

.message-section {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  color: #364141;
}

.details-message {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  color: #515e5f;
}

.message-box {
  width: 70%;
}

.notification-time {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 19px;
  color: #515e5f;
  margin-right: 10px;
}

.campagin-head {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #364141;
}

.sub-head {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  color: #364141;
  margin-top: 10px;
}

.campagin-section {
  background: #ffffff;
  border: 1px solid #bcbcbc;
  border-radius: 12px;
  padding: 10px 20px;
  margin: 10px;
}

.campagin-obejctive {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #000000;
}

.campagin-btn {
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  padding: 10px 30px;
}

.objective_active {
  background-color: #000;
  color: #fff;
}

.duration {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  text-align: left;
  margin-bottom: 10px;
  color: #000000;
}

.duration-input {
  border: 1px solid #000000;
  border-radius: 10px;
  padding: 10px 20px;
}

.create-btn {
  justify-content: right;
  display: flex;
}

.create {
  padding: 10px 40px;
}

.select-post {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height, or 133% */
  text-align: center;
  color: #000000;
  margin-top: 20px;
}

/* ...........signup css............ */

.sign-head {
  /* /* border-bottom: 3px solid #edebeb; */
  /* box-shadow: 0px 4px 8px rgba(39, 51, 51, 0.1); */
}

.signlogo {
  width: 30%;
  padding-left: 24px;
}

.already_label {
  margin-right: 10px;
}

.login_btn {
  background-color: transparent;
  border: 1px solid #d5d5d5;
  border-radius: 20px;
  padding: 5px 10px;
  margin-right: 10px;
  text-decoration: none;
  color: #000;
}

.text-right {
  text-align: right;
}

.bg-image {
  background-image: url("./Assets/oolook_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 55px;
}

.bg-images {
  background-image: url("./Assets/oolook_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 20px;
  min-height: 100%;
}

.onbording_box {
  background-color: #ffffff;
  border-radius: 20px;
  min-height: 100%;
}

/* progess Steps  */

.progress-container {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 30px;
  max-width: 100%;
  margin: 0 3vw;
  align-items: center;
}

.progress-container::before {
  content: "";
  background-color: var(--line-border-empty);
  position: absolute;
  top: 18px;
  left: 20px;
  transform: translateY(-50%);
  height: 4px;
  width: 80%;
  z-index: -1;
}

.progress {
  background-color: var(--line-border-fill);
  position: absolute;
  top: 18px;
  left: 20px;
  transform: translateY(-50%);
  height: 4px;
  max-width: 80%;
  width: 0%;
  z-index: -1;
  transition: 400ms ease;
}

.text-wrap {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* width: 100%; */
}

.horizontal_line {
  background-color: #9f92f3;
  height: 4px;
  width: 42%;
  border-radius: 10px;
}

.text-wrap p {
  font-weight: 400;
  font-size: 12px;
  color: var(--text-empty);
  width: fit-content;
}

.text-wrap.actives p {
  font-weight: 400;
  color: var(--text-fill);
  transition: 400ms ease;
}

.circle {
  background-color: #d4d4d4;
  border: 3px solid var(--line-border-empty);
  color: var(--text-empty);
  font-weight: 700;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  position: relative;
  /* Position the child element */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 400ms ease;
  opacity: 0.3;
  z-index: 1000;
}

.text-wrap.actives div.circle {
  border-color: var(--line-border-fill);
  color: #ffffff;
  background: #9f92f3;
  opacity: 1;
}

/* Progess step end */

.first-field {
  /* display: flex; */
  /* flex-direction: row; */
  margin-top: 30px;
}

.text1 {
  color: #273333;
  font-size: 32px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  margin-top: 60px;
}

.text2 {
  color: #515e5f;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.5px;
  text-align: center;
  margin-top: 10px;
}

.field-div {
  text-align: center;
}

.long-text {
  /* text-align: center; */
  margin-top: 10px;
  /* margin-left: 38vw;
  margin-right: 36vw; */
  font-size: 13px;
}

b {
  color: #2079c3;
}

.create-btn {
  justify-content: center;
  width: 100%;
  padding: 5px;
  background: #2079c3;
  color: #fff;
  border: hidden;
  border-radius: 5px;
  /* align-items: center; */
  /* text-align: center; */
}

.btn-div {
  margin-top: 20px;
  width: 100%;
}

/* ................Frame css............ */
.frame-part {
  overflow-x: auto;
  overflow-y: auto;
}

.img-div {
  text-align: center;
  margin-top: 70px;
}

.frame-img {
  width: 100%;
  height: auto;
}

.lable-text {
  /* margin-left: 45px; */
  padding: 5px;
  margin-top: 10px;
  font-size: 14px;

  text-align: left;
  /* font-weight: bold; */
}

.input-div {
  /* margin-left: 50px; */
}

.input-field {
  width: 100%;
  background: #e8f0fe;
  padding: 7px;
  border: 1px solid #b8b8b8;
  border-radius: 5px;
}

.line {
  font-weight: bold;
  font-size: 22px;
  margin-left: 50px;
  margin-top: 20px;
}

.input-div1 {
  flex-direction: row;
  display: flex;
}

.input-div2 {
  flex-direction: row;
  display: flex;
  margin-left: 48px;
}

.lable-text1 {
  margin-left: 45px;
  padding: 5px;
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
}

.lable-text2 {
  margin-left: 85px;
  padding: 5px;
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
}

.input-field1 {
  width: 42%;
  background: #e8f0fe;
  padding: 7px;
  border: 1px solid #b8b8b8;
  border-radius: 5px;
}

.input-field2 {
  width: 43%;
  background: #e8f0fe;
  padding: 7px;
  border: 1px solid #b8b8b8;
  border-radius: 5px;
  margin-left: 15px;
}

.submit-btn {
  width: 78%;
  background: #2c4bff;
  padding: 7px;
  border-radius: 5px;
  color: #fff;
  border: hidden;
}

.setting {
  color: #636363;
  text-align: center;
  font-size: 14px;
  margin-top: 50px;
}

/* ....................onboarding css................... */

.onboard-line1 {
  text-align: center;
  font-size: 30px;
  /* margin-top: 60px; */
  font-weight: bold;
  color: #3d3d3d;
  line-height: 44px;
}

.onboard-line2 {
  text-align: center;
  font-size: 36px;
  /* margin-top: 60px; */
  font-weight: bold;
  color: #000000cc;
}

.box-div {
  display: flex;
  flex-direction: row;
  text-align: center;
  /* background: blue; */
}

.box1 {
  height: 28vh;
  border: 1px solid #b8b8b8;
  width: 18%;
  /* margin-left: 25vw; */
  margin-top: 30px;
  border-radius: 5px;
  box-shadow: 0px 2px 8px 0px #0000001a;
}

.active_box {
  border: 2px solid #9f92f3 !important;
}

.box2 {
  height: 28vh;
  border: 1px solid #b8b8b8;
  box-shadow: 0px 2px 8px 0px #0000001a;
  width: 22%;
  /* margin-left: 40px; */
  margin-top: 30px;
  border-radius: 5px;
}

.onboard-img {
  width: 45%;
  margin-top: 20px;
}

.content {
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: -1.1%;
  margin-top: 10px;
}

/* .....login css......................... */

/* part1 */

.log-box1 {
  margin-top: 50px;
}

.login-img {
  width: 40%;
  margin-left: 30px;
}

/* .log-box1{
  height: 28vh;
  width: 30%;
  border-radius: 5px;
  background: #fff;
}

.log-box2{
  display: flex;
  flex-direction: row;
}

.log-box21{
  height: 28vh;
  width: 30%;
  border-radius: 5px;
  background: #fff;
} */

.part1-text1 {
  line-height: 50.6px;
  color: #fff;
  font-size: 40px;
  font-weight: 400;
  margin-left: 72px;
  margin-top: 20px;
  margin-right: 82px;
}

.form-section {
  margin: 0 30px;
}

.part1-text2 {
  margin-right: 30%;
  line-height: 24px;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  margin-left: 72px;
  margin-top: 20px;
}

.part1-text3 {
  margin-right: 30%;
  line-height: 24px;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  margin-left: 72px;
  /* border-bottom: 2px solid #6F5ED3; */
  text-decoration: underline #6f5ed3 2px solid;
}

.message {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  /* padding-left: 12%; */
  width: 100%;
  font-size: 14px;
  font-weight: bold;
}

.signdiv {
  margin-top: 20px;
}

.signdiv a {
  color: #116daa;
  width: 60%;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
}

.sign-line {
  background-color: transparent;
  border: hidden;
  font-size: 14px;
  /* margin-left: 12%; */
  width: 149px;
  color: #000000;
}

.remember_me {
  font-size: 11px;
  font-weight: 100;
  display: flex;
  align-items: center;
}

.remember_check {
  margin-right: 10px;
}

.login-line {
  font-size: 18px;
  font-weight: 700;
  margin-top: 15px;
}

.login-btn {
  background: #2079c3;
  width: 100%;
  padding: 7px;
  border-radius: 5px;
  color: #fff;
  border: hidden;
}

/* Side bar css */

.sidebar {
  background-color: #273333;
  box-shadow: 0 15px 10px rgba(0, 0, 0, 0.3);
  height: 100vh;
  /* width: 100%; */
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100000;
  /* width: 60px; */
}

.sidenav_list {
  list-style: none;
  color: #fff;
  /* position: relative; */
  padding-left: 0 !important;
  right: 25px;
  top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sub_menu {
  display: none;
}

.sub_menu_show {
  display: block;
  padding: 10px;
}

.sub_menu_show li {
  list-style: none;
  padding: 10px;
}

.sub_menu_show a {
  text-decoration: none;
  color: #ffffff;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.links {
  margin-left: 20px;
}

.active {
  background: #515e5f;
  padding: 10px;
  border-radius: 10px;
  /* width: fit-content; */
}

.submenu_active {
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  color: #273333;
  font-size: 12px;
  font-weight: bold;
  line-height: 19.5px;
  width: fit-content;
}

.links li {
  /* margin-top: 20px; */
  margin-left: 20px;
  /* background-color: red; */
}

.links {
  margin: 5px 0;
  padding: 10px;
}

.side-right {
  background-color: #e9ecef;
}

.sidelogo {
  width: 70%;
  /* padding: 20px; */
}

.logodiv {
  text-align: center;
  margin-bottom: 45px;
}

.log_out {
  background-color: transparent;
  border: none;
}

.displaybtn {
  display: none;
}

/* .links:hover{
  background :linear-gradient(to right, #BF953F, #FCF6BA, #FBF5B7, #AA771C)
} */

.links {
  display: flex;
  flex-direction: row;
}

.sidenav_list a {
  text-decoration: none;
  color: white;
  justify-content: center;
  display: flex;
  width: fit-content;
  margin-bottom: 15px;
  /* margin-left: 10px; */
}

.icon {
  margin-top: 20px;
  color: #b38728;
}

.sideicon {
  width: 22px;
  height: 22px;
  /* margin-top: 20px; */
}

/* sidebar balance section */
.balance_section {
  background: #515e5f;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  /* position: absolute; */
  /* bottom: 0; */
  width: 92%;
}

.photography {
  width: 60px;
  height: 60px;
}

.label_section label {
  font-family: "Roboto";
  font-size: 8px;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
  margin-left: 10px;
}

.label_section h5 {
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
  margin-left: 10px;
}

.balance-box1 {
  justify-content: space-evenly;
}

.balance-box2 {
  justify-content: space-evenly;
  margin-top: 20px;
}

.bottom-section {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.account_setting {
  padding: 10px;
  justify-content: center;
  border: none;
  background-color: transparent;
  width: 100%;
}

.account_setting label {
  font-family: "Roboto";
  font-size: 13px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
}

.arrow {
  width: 20px;
}

.avtar {
  width: 23px;
  height: 23px;
}

.header {
  padding: 10px;
  border-bottom: 1px solid #dee1e1;
  justify-content: space-between;
}

.welcome_msg h4 {
  font-family: "Roboto";
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #364141;
  margin-left: 10px;
}

.trial_msg {
  font-family: "Roboto";
  font-size: 13px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #364141;
  padding-top: 10px;
  margin-right: 10px;
}

.feature_btn {
  font-family: "Roboto";
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  background-color: #6f5ed3;
  color: #fff;
  width: 167px;
  align-items: center;
  display: flex;
}

.feature_btn:hover {
  background-color: #6f5ed3;
  color: #fff;
}

.feature_btn img {
  width: 20px;
  margin-right: 10px;
}

.subscribe_btn {
  background-color: #2079c3;
  color: #fff;
  font-family: "Roboto";
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  padding: 8px 10px;
  margin-left: 10px;
}

.subscribe_btn:hover {
  background-color: #2079c3;
  color: #fff;
}

/* Dashboard Screen */

.Dash_Notification {
  padding: 15px;
}

.Dash_Notification h1 {
  font-family: "Roboto";
  font-size: 24px;
  font-weight: 800;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}

.Dash_section {
  margin: 10px;
  background-color: #fff;
  border-top: 8px solid #9180f4;
  padding: 8px 0px 0px 0px;
  border-radius: 6px;
  border: 8px 0px 0px 0px;
  padding: 15px;
}

.Dashboard {
  /* background-color: #F3F4F4; */
  background-color: #f3f4f4;
  padding: 0 !important;
}

.Dash_Notification label {
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.dash_notifi_box {
  width: 100%;
}

.dash_box1 {
  border-radius: 10px;
  box-shadow: 0px 2px 4px 0px #2733333d;
  background: #ffffff01;
  overflow: hidden;
  margin: 0 10px;
}

.box1_title label {
  font-family: "Roboto";
  font-size: 13px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #515e5f;
  padding: 10px;
}

.box1_title p {
  font-family: "Roboto";
  font-size: 13px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #515e5f;
  word-wrap: wrap;
  width: 15vw;
  padding: 10px;
}

.boxsection {
  justify-content: space-evenly;
}

.activity {
  padding: 15px;
}

.activity h4 {
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #273333;
}

.performance_section {
  border: 1px solid #dee1e1;
  border-radius: 6px;
  background-color: #fff;
  height: fit-content;
}

.performance_section label {
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.titles {
  border-bottom: 1px solid #dee1e1;
  padding: 15px;
}

.performance_tabel {
  width: 100%;
}

.title_box1 {
  justify-content: space-between;
}

.title_box1 label {
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #273333;
}

.summary {
  border: 1px solid #dee1e1;
  background-color: #fff;
  border-radius: 6px;
}

.summary_header {
  justify-content: space-between;
  border-bottom: 1px solid #dee1e1;
  padding: 10px;
}

.summary_header h4 {
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  padding: 8px 0px;
}

.summary_create {
  border-radius: 6px;
  border: 1px solid #000;
  padding: 5px 20px;
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 10px;
}

.summary_inner_box label {
  font-family: "Roboto";
  font-size: 13px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 10px;
}

.summary_inner_box select {
  border-radius: 6px;
  border: 1px;
  border: 1px solid #6e797a;
  color: #6e797a;
  padding: 8px;
  font-family: "Roboto";
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}

.summary_table {
  border: none;
  width: 100%;
}

.summary_table thead {
  border-bottom: 2px solid #dee1e1;
}

.summary_table thead th {
  padding: 10px;
}

.summary_table thead tr th:last-child {
  text-align: right;
}

.summary_table thead tr th:nth-child(3) {
  text-align: right;
}

.summary_table tbody {
  font-family: "Roboto";
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: #515e5f;
}

.summary_table tbody tr {
  border-bottom: 1px solid #dee1e1;
}

.summary_table tbody tr td:last-child {
  text-align: right;
}

.summary_table tbody tr td:nth-child(3) {
  text-align: right;
}

.summary_table tbody td {
  padding: 10px;
  font-family: "Roboto";
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
  color: #515e5f;
}

.table_section {
  border: 1px solid #c2c5c5;
  margin: 10px;
  border-radius: 6px;
  padding: 10px;
}

/* Calender View */
.weeks {
  display: flex;
  justify-content: space-around;
  text-align: center;
}

.previous_dates {
  position: relative;
}

.previous_dates div {
  text-align: left;
  height: 10%;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-top: 6px;
  position: absolute;
  top: 0;
}

.days_section {
  overflow: hidden;
  margin-bottom: 10px;
  margin-top: 10px;
}

.days_section table {
  text-align: center;
  width: 100%;
}

/* redner empty */
.empty {
  background-color: #eaeaea;
  width: 140px;
  height: 200px;
  border: 1px solid #cbcaca;
}

/* render dates */
.weeks-day {
  border: 1px solid #dee1e1;
  background-color: #fff;
  width: 140px;
  height: 200px;
  padding: 10px;
  text-align: left;
  position: relative;
}

.previous-weeks-day {
  border: 1px solid #cbcaca;
  background-color: #ffffff;
  width: 140px;
  height: 200px;
  padding: 10px;
  text-align: left;
}

/* week name head */
.week-day {
  border-width: 0px 0px 1px 1px;

  border-style: solid;

  border-color: #dee1e1;
}

.tasks {
  border: 1px solid #dee1e1;
  border-radius: 6px;
  padding: 5px 20px;
  display: flex;
  justify-content: space-between;
  width: 80px;
  margin-bottom: 10px;
}

.action_task {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  width: 85%;
}

.add_task {
  border: 1px dotted #364141;
  border-style: dashed;
  background-color: transparent;
  width: 45%;
  /* text-align: center; */
  text-decoration: none;
  color: #000;
  justify-content: center;
}

.current {
  border-width: 4px 2px 2px 2px;

  border-style: solid;

  border-color: #2b87d3;
}

.arrow_btn {
  border: 1.5px solid #364141;
  padding: 10px;
  /* margin: 0 10px; */
  border-radius: 10px;
}

.approval_header h4 {
  margin-bottom: 0px !important;
  padding: 5px;
  font-size: 18px;
  font-weight: 700;
  align-items: center;
  display: flex;
}

.approval_header h5 {
  margin-bottom: 0px !important;
  align-items: center;
  display: flex;
  font-weight: bold;
  font-size: 18px;
  font-weight: 700;
}

.menu_btn_1 {
  background-color: transparent;
  border: none;
  padding: 8px;
  border-radius: 8px;
}

.menu_btn_1 img {
  width: 20px;
}

.menu_active {
  background-color: #364141 !important;
  border-radius: 10px;
  color: #fff;
}

.menu_btn_2 {
  background-color: transparent;
  border: none;
  padding: 8px;
}

.menu_btn_2 img {
  width: 20px;
}

.menu_section {
  border: 1px solid #364141;
  padding: 2px;
  border-radius: 10px;
}

/* Approval Page */

.approval_header {
  font-family: "Roboto";
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #364141;
  padding: 10px;
}

.filter_table {
  width: 100%;
  animation: 0.5s ease-out 0s 1 slideInFromLeft;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateY(50%);
  }

  100% {
    transform: translateY(0);
  }
}

.filter_table tr {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #dee1e1;
}

.filter_table tr td {
  display: flex;
  flex-direction: column;
  border-right: 1px solid #dee1e1;
  width: 100%;
  padding: 10px;
}

.filter_table tr td:last-child {
  border-right: none;
}

.filter_table tr td label {
  font-family: "Roboto";
  font-size: 13px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #273333;
}

.filter_table tr td select {
  font-family: "Roboto";
  font-size: 11px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  background-color: transparent;
  border: none;
  width: 100%;
}

.sort_menu {
  text-align: right;
  padding: 10px;
}

.sort_menu label {
  font-family: "Roboto";
  font-size: 13px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  margin-right: 10px;
}

.sort_menu select {
  font-family: "Roboto";
  font-size: 13px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  background-color: transparent;
  border: 1px solid #273333;
  border-radius: 6px;
  padding: 5px;
}

/* Approval  Post secction */

.post_section {
  margin-top: 20px;
  margin: 50px;
  border: 1px solid #ffbc00;
  border-radius: 8px;
}

.post_header {
  background: #fdefcd;
  border-radius: 8px 8px 0 0;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.post_header_img {
  width: 40px;
  border-radius: 50% !important;
}

.post_header label {
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #364141;
}

.status {
  font-family: "Roboto" !important;
  font-size: 11px !important;
  font-weight: 400 !important;
  line-height: 13px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #515e5f !important;
}

.date_section label {
  font-family: "Roboto" !important;
  font-size: 11px !important;
  font-weight: 400 !important;
  line-height: 19px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #515e5f !important;
}

.post_details {
  padding: 30px;
}

.post_details p {
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #364141;
}

.post_photo {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.post_photo img {
  /* width: 50%; */
  width: 32%;
}

.post_footer {
  padding: 10px 50px;
}

.post_footer img {
  width: 40px;
}

.post_footer label {
  font-family: "Roboto";
  font-size: 11px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #364141;
  margin-left: 10px;
}

/* Create Post */

.Create_post_head {
  font-family: "Roboto";
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #273333;
  padding: 10px;
  border-bottom: 1px solid #dee1e1;
}

.create_main_section {
  padding: 20px;
}

.pages {
  border: 1px solid #dee1e1;
  padding: 10px;
  border-radius: 6px;
}

.pages_box {
  border: 1px solid #dee1e1;
  background-color: #fff;
  /* width: fit-content; */
  padding: 10px 10px;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  width: 10vw;
}

.pages_box img {
  width: 20px;
}

.pages_box label {
  font-family: "Roboto";
  font-size: 13px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
}

.form {
  padding: 15px;
}

.check_box {
  font-family: "Roboto";
  font-size: 13px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #273333;
  background-color: #fff;
  padding: 10px;
  border-radius: 6px 6px 0 0;
  border: 1px solid #dee1e1;
}

.check_box span {
  margin-right: 10px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.switch_box {
  border: 1px solid #dee1e1;
}

.textarea_section textarea {
  width: 100%;
  border: none;
  height: 100px;
  border-radius: 0 0 6px 6px;
  border: 1px solid #dee1e1;
}

/* .pinterest_section {
  margin-top: 30px;
  border: 1px solid #dee1e1;
  background-color: #fff;
  border-radius: 8px;
}

.pinterest_head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border: 1px solid #dee1e1;
  border-radius: 8px 8px 0 0;
}

.pinterest_head label {
  font-family: "Roboto";
  font-size: 13px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #364141;
}

.pinterest_input {
  padding: 20px;
}

.pinterest_input input {
  width: 100%;
  border: 1px solid #6e797a;
  border-radius: 6px;
  padding: 8px;
  margin: 10px 0;
} */

.dropdowns {
  width: 100%;
  border: 1px solid #6e797a;
  border-radius: 6px;
  padding: 8px;
  margin: 10px 0;
}

.pinterest_input select {
  font-family: "Roboto";
  font-size: 11px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.pinterest_head div {
  font-family: "Roboto";
  font-size: 13px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  border: 1px solid #6e797a;
  border-radius: 6px;
  padding: 5px 10px;
}

.pinterest_input label {
  font-family: "Roboto";
  font-size: 13px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.pinterest_input table {
  width: 100%;
}

.pinterest_input tr td {
  font-family: "Roboto";
  font-size: 13px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #273333;
}

.time_section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.time_section select {
  margin-right: 5px;
}

.time_section label {
  margin-top: 15px;
  margin-right: 5px;
}

.add_more {
  border: 1px solid #dee1e1;
  border-radius: 0 0 6px 6px;
  padding: 10px;
}

.add_more i {
  color: #116daa;
}

.add_more label {
  font-family: "Roboto";
  font-size: 13px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: #116daa;
  margin-left: 10px;
}

.pinterest_input ul li {
  list-style-image: url("./Assets/exclamation.png");
  padding-inline-start: 1ch;
}

.pinterest_input ul li::marker {
  font-size: 2rem;
}

/* Inbox */

.message_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Inbox_remaining {
  font-family: "Roboto";
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: #364141;
  border: 1px solid #364141;
  border-radius: 6px;
  padding: 8px 10px;
  margin: 0 5px;
}

.Filter_btn {
  background-color: #273333;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Filter_btn img {
  width: 15px;
  margin-right: 5px;
}

.reset_message {
  display: flex;
  flex-direction: row !important;
  justify-content: right;
}

.reset_btn {
  color: #116daa;
}

.detail_message_section {
  margin: 20px 50px;
}

.message_detail_header {
  background-color: #dee1e1;
  border: 1px solid #dee1e1;
}

.message_detail_header img {
  border-radius: 20px;
  height: 40px;
}

.message_detail {
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #364141;
  background-color: #fff;
  border-radius: 0 0 6px 6px;
  padding: 20px;
}

.view_thread_btn {
  font-family: "Roboto";
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
}

.message_footer {
  display: flex;
  justify-content: space-between;
}

/* Profile Report Page */
.performace_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.tab_btn {
  background-color: transparent !important;
  border: none;
  padding: 10px;
  border-bottom: 1px solid #dee1e1;
  margin: 10px 0;
  color: #273333;
  font-size: 13px;
  font-weight: 700;
}

.active_tab {
  border-bottom: 3px solid #2079c3;
  color: #2079c3;
}

.summery_section {
  background-color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  /* padding: 20px; */
}

.summary_head_box {
  border-bottom: 1px solid #dee1e1;
}

.summary_head_box h4 {
  padding: 20px 0 0 20px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.summary_head_box label {
  padding: 0 20px 10px 20px;
  font-size: 13px;
  color: #364141;
  font-weight: 400;
  line-height: 21.33px;
}

.date_txt {
  font-family: "Roboto";
  font-size: 11px !important;
  font-weight: 400 !important;
  line-height: 19px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
}

.performace_subhead {
  display: flex;
}

.highlight_txt {
  color: #116daa;
  font-weight: 600;
  line-height: 12.89px;
}

.performace_date {
  font-family: "Roboto";
  font-size: 13px !important;
  font-weight: 700 !important;
  line-height: 16px !important;
  letter-spacing: 0em !important;
  text-align: center;
}

.performace_date label {
  font-family: "Roboto";
  font-size: 11px;
  font-weight: 400;
  line-height: 11px;
  letter-spacing: 0em;
  text-align: center;
  margin-left: 10px;
}

/* Dimple Css */

/* ...........signup css............ */

.sign-head {
  /* /* border-bottom: 3px solid #edebeb; */
  /* box-shadow: 0px 4px 8px rgba(39, 51, 51, 0.1); */
}

.signlogo {
  width: 50%;
  padding-left: 24px;
}

.text1 {
  color: #273333;
  font-size: 32px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  margin-top: 60px;
}

.text2 {
  color: #515e5f;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.5px;
  text-align: center;
  margin-top: 10px;
}

.field-div {
  text-align: center;
}

.field {
  width: 100%;
  padding: 5px;
  border: 1px solid #6e797a;
  border-radius: 5px;
  overflow: hidden;
}

.sign_form_section {
  margin: 0 14vw;
  max-width: 430px;
}

.field1 {
  width: 100%;
  padding: 5px;
  border: 1px solid #6e797a;
  border-radius: 5px;
  overflow: hidden;
  /* margin-left: 35vw; */
}

.field2 {
  width: 100%;
  padding: 5px;
  border: 1px solid #6e797a;
  border-radius: 5px;
  overflow: hidden;
  /* margin-left: 10px; */
}

.text {
  /* margin-left: 35vw; */
  margin-top: 10px;
  font-weight: bold;
}

.long-text {
  text-align: center;
  margin-top: 10px;
  font-size: 13px;
}

.PhoneInputInput {
  width: 100%;
  padding: 5px;
  border: 1px solid #6e797a;
  border-radius: 5px;
  overflow: hidden;
}

.review-label {
  color: #2f2a60;
  font-size: 30px;
  text-align: center;
}

.review_name {
  font-size: 20px;
  color: #2f2a60;
  font-weight: bold;
}

b {
  color: #2079c3;
}

.text0 {
  /* margin-left: 35vw; */
  font-weight: bold;
}

.text00 {
  /* margin-left: 10vw; */
  font-weight: bold;
}

.create-btn {
  justify-content: center;
  width: 100%;
  padding: 5px;
  background: #2079c3;
  color: #fff;
  border: hidden;
  border-radius: 5px;
  /* align-items: center; */
  /* text-align: center; */
}

.btn-div {
  margin-top: 20px;
}

/* ................Frame css............ */
.frame-part {
  overflow-x: auto;
  overflow-y: auto;
}

.img-div {
  text-align: center;
  margin-top: 70px;
}

.frame-img {
  width: 100%;
  height: auto;
}

.lable-text {
  /* margin-left: 45px; */
  padding: 5px;
  margin-top: 10px;
  font-size: 14px;

  text-align: left;
  /* font-weight: bold; */
}

.input-div {
  /* margin-left: 50px; */
}

.input-field {
  width: 100%;
  background: #e8f0fe;
  padding: 7px;
  border: 1px solid #b8b8b8;
  border-radius: 5px;
}

.line {
  font-weight: bold;
  font-size: 22px;
  margin-left: 50px;
  margin-top: 20px;
}

.input-div1 {
  flex-direction: row;
  display: flex;
}

.input-div2 {
  flex-direction: row;
  display: flex;
  margin-left: 48px;
}

.lable-text1 {
  margin-left: 45px;
  padding: 5px;
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
}

.lable-text2 {
  margin-left: 85px;
  padding: 5px;
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
}

.input-field1 {
  width: 42%;
  background: #e8f0fe;
  padding: 7px;
  border: 1px solid #b8b8b8;
  border-radius: 5px;
}

.input-field2 {
  width: 43%;
  background: #e8f0fe;
  padding: 7px;
  border: 1px solid #b8b8b8;
  border-radius: 5px;
  margin-left: 15px;
}

.submit-btn {
  width: 78%;
  background: #2c4bff;
  padding: 7px;
  border-radius: 5px;
  color: #fff;
  border: hidden;
}

.setting {
  color: #636363;
  text-align: center;
  font-size: 14px;
  margin-top: 50px;
}

/* ....................onboarding css................... */

.onboard-line1 {
  text-align: center;
  font-size: 30px;
  /* margin-top: 60px; */
  font-weight: bold;
  color: #3d3d3d;
  line-height: 44px;
}

.onboard-line2 {
  text-align: center;
  font-size: 36px;
  /* margin-top: 60px; */
  font-weight: bold;
  color: #000000cc;
}

.box-div {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  /* background: blue; */
}

.box1 {
  height: 28vh;
  border: 1px solid #b8b8b8;
  width: 18%;
  /* margin-left: 25vw; */
  margin-top: 30px;
  border-radius: 5px;
  box-shadow: 0px 2px 8px 0px #0000001a;
}

.box2 {
  height: 28vh;
  border: 1px solid #b8b8b8;
  box-shadow: 0px 2px 8px 0px #0000001a;
  width: 22%;
  /* margin-left: 40px; */
  margin-top: 30px;
  border-radius: 5px;
}

.onboard-img {
  width: 45%;
  margin-top: 20px;
}

.content {
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: -1.1%;
  margin-top: 10px;
}

/* .....login css......................... */

/* ..............photographer css.............. */

.photo-box {
  padding: 20px 20px;
  margin: 10px;
  border-radius: 12px;
  box-shadow: 0 0 10px #c9c9c9;
}

.campagin-btn1 {
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  padding: 10px 30px;
  color: #2f69db;
  border: 2px solid #2f69db;
  border-radius: 8px;
}

/* ..............Billing Css................  */

.bill-box {
  padding: 20px 20px;
  margin: 10px;
  border-radius: 12px;
  box-shadow: 0 0 10px #c9c9c9;
  height: auto;
  width: 50%;
}

.bill-head {
  font-size: 18px;
  font-weight: 700;
  color: #3d3d3d;
}

.bill-text {
  font-size: 16px;
  line-height: 28px;
  font-weight: 700;
  margin-top: 15px;
  color: #3d3d3d;
}

.bill-field {
  width: 100%;
  padding: 7px;
  border: 1px solid #b8b8b8;
  border-radius: 5px;
}

.short-lable {
  display: flex;
  flex-direction: row;
}

.short-lable1 {
  font-size: 16px;
  line-height: 28px;
  font-weight: 700;
  color: #3d3d3d;
}

.short-lable2 {
  font-size: 16px;
  line-height: 28px;
  font-weight: 700;
  color: #3d3d3d;
  margin-left: 30vw;
}

/* OverLay Css */
#overlay {
  position: fixed;
  width: 100%;
  /* Full width (cover the whole page) */
  height: 100%;
  /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Black background with opacity */
  z-index: 1000;
  /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  /* Add a pointer on hover */
}

#Chart_overlay {
  position: fixed;
  width: auto;
  /* Full width (cover the whole page) */
  height: auto;
  /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Black background with opacity */
  z-index: 1000;
  /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  /* Add a pointer on hover */
}

#overlaysidebar {
  position: fixed;
  width: 100%;
  /* Full width (cover the whole page) */
  height: 100%;
  /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0);
  /* Black background with opacity */
  z-index: 1000;
  /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  /* Add a pointer on hover */
}

#overlay img {
  width: 20%;
  /* animation: rotation 6s infinite linear; */
  /* height: 50%; */
}

/* @keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.loader_center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.steps_main_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* organization Details */

.form_fields label {
  font-size: 12px;
  font-family: Roboto;
}

.form_fields input[type="text"] {
  border: 1px solid #979797;
  border-radius: 5px;
  padding: 5px;
}

.form_fields label {
  margin: 10px 0 0 0;
}

.form_fields input[type="number"] {
  border: 1px solid #979797;
  border-radius: 5px;
  padding: 5px;
}

.form_section {
  margin: 0 30vw;
}

.upload_Logo {
  border: 1px solid #979797;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
}

.add_color_btn {
  border: 1px solid #979797;
  background-color: transparent;
  border-radius: 10px;
  padding: 20px;
  width: 70px;
}

.add_color_section {
  width: 60px;
  max-width: 60px;
}

.selected_color {
  width: 60px;
  max-width: 60px;
  border-radius: 10px;
  height: 58px;
}

/* Connect Profile 2 */
.social_logo {
  width: 50px;
}

.social_box {
  border: 1px solid #d5d5d5;
  border-radius: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100% !important;
  margin: 0 10px;
  /* width: 90% !important; */
}

.connect_btn {
  background-color: transparent;
  border: 1px solid #75afe8;
  border-radius: 25px;
  padding: 10px 15px;
}

/* .slick-slide
{
  width: 20.5vw !important;
  margin: 10px;
  height: 50% !important;
}

.slider_section
{
  overflow-y: hidden;
} */

.color_selection {
  position: relative;
  left: 50px;
}

.slick-slide {
  padding-left: 10px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  height: fit-content !important;
}

.prev_arrow_btn {
  position: absolute;
  top: 42%;
  left: 10px;
  padding: 15px;
  background-color: #fff;
  border: 1px solid #979797;
  border-radius: 30px;
  z-index: 100;
}

.right_arrow_btn {
  position: absolute;
  top: 42%;
  right: 10px;
  padding: 15px;
  background-color: #fff;
  border: 1px solid #979797;
  border-radius: 30px;
  z-index: 100;
}

.prev_arrow_btn i {
  color: #979797;
}

.right_arrow_btn i {
  color: #979797;
}

/* New Publish page Dimple Css */
.twit-head {
  display: flex;
  width: 100%;
}

.head-1 {
  margin-top: 20px;
  color: #273333;
  font-weight: bold;
}

.cross-btn {
  border: hidden;
  background: #273333;
  color: #fff;
  border-radius: 6px;
  font-size: 14px;
}

/* .twit-box{
  display: flex;
  flex-direction: row;
} */

.twit-box1 {
  border-radius: 6px;
  background-color: #f3f4f4;
  width: 60%;
  /* margin-left: 40px; */
  overflow-y: auto;
  height: 95vh;
}

.twit-box1::-webkit-scrollbar {
  width: 5px;
}

.twit-box1::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.twit-box1::-webkit-scrollbar-thumb {
  background: #7d7d7d;
  border-radius: 10px;
}

/* Handle on hover */
.twit-box1::-webkit-scrollbar-thumb:hover {
  background: #7d7d7d;
}

.box1-text {
  color: #3d3d3d;
  font-size: 16px;
  font-weight: bold;
  margin-top: 20px;
}

.twit-box2 {
  background-color: #f3f4f4;
  /* width: 47%; */
  border-radius: 6px;
  height: 97vh;
  margin-left: 10px;
  overflow-y: auto;
}

.twit-box2::-webkit-scrollbar {
  width: 5px;
}

.twit-box2::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.twit-box2::-webkit-scrollbar-thumb {
  background: #7d7d7d;
  border-radius: 10px;
}

/* Handle on hover */
.twit-box2::-webkit-scrollbar-thumb:hover {
  background: #7d7d7d;
}

.box2-head {
  display: flex;
  flex-direction: row;
}

.ques-icon {
  margin-top: 25px;
  margin-left: 5px;
}

.Twit-box2Img {
  margin-left: 6vw;
  margin-top: 55px;
}

.Tprofile {
  /* margin-top: 70px; */
  width: 50px;
  /* margin-left: 20px; */
}

.sm-box-twitter {
  display: flex;
  flex-direction: row;
}

.twitter-cross {
  width: 20px;
}

.box1-small {
  border: 2px solid #f3f4f4;
  width: 100%;
  height: 44vh;
  /* padding: 10px; */
  margin: 10px 0;
}

.assist-btn {
  /* border: 2px solid #f3f4f4; */
  border: none;
  font-size: 12px;
  padding: 10px;
  background: transparent;
  border-radius: 5px;
  color: #59626a;
}

.assist-btn img {
  width: 20px;
}

.document-sec {
  border: 1.6px solid #ceced1;
  border-style: dashed;
  width: 15%;
  margin-top: 12vh;
  padding: 10px;
}

.uplode-file {
  font-size: 12px;
  color: #59626a;
  text-align: center;
}

.link-btn {
  background: transparent;
  border: hidden;
  color: blue;
}

.date {
  font-size: 12px;
}

.date b {
  color: #000;
}

.edit-btn {
  /* background: transparent; */
  border: 1.6px solid #b3b5b7;
  margin-left: 20px;
  width: 50px;
  border-radius: 5px;
  color: #59626a;
  margin-top: 5px;
}

.draft-btn {
  font-size: 12px;
  border: 1.6px solid #b3b5b7;
  margin: 10px;
  width: 115px;
  border-radius: 5px;
  color: #59626a;
  margin-top: 5px;
  padding: 5px;
}

.draft-btn-enable {
  font-size: 12px;
  border: 1.6px solid #2079c3;
  margin: 10px;
  width: 115px;
  border-radius: 5px;
  background-color: #2079c3;
  color: #fff;
  margin-top: 5px;
  padding: 5px;
}

.emoji_btn {
  background: transparent;
  border: none;
  /* border-left: 1px solid #b3b5b7; */
  /* width: 50px; */
}

.emoji_btn img {
  width: 50px;
  object-fit: contain;
}

.emoji_picker {
  position: absolute;
  left: 22vw;
  top: 15vw;
}

.social_media {
  border: 1px solid #e0e0e0;
  padding: 5px;
  margin-top: 15px;
  border-radius: 10px;
}

.submenu {
  background-color: #364141;
  position: fixed;
  top: 0.1px;
  /* left: 105px; */
  left: 8%;
  min-height: 100vh;
  width: 20vw;
  z-index: 1000;
}

.sub_menu_header {
  border-bottom: 1px solid #000000;
  padding: 10px 10px 10px 20px;
}

.user_name {
  color: #c8cccc;
  font-size: 11px;
  font-weight: 400;
}

.page_name {
  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
}

.fontpicker {
  position: absolute;
}

/* Overview css */
.total_numbers {
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
}

.differance_percentage {
  color: #067c7c;
  margin-left: 10px;
}

.differance_percentage i {
  transform: rotate(45deg);
  margin-right: 8px;
  color: #067c7c;
}

.summary_table {
  margin-bottom: 0px;
}

.summary_table tr td {
  border: 1px solid #dee1e1;
  padding: 10px;
  border-bottom: 0px;
  border-left: 0px;
}

.summaryHead {
  color: #929a9b;
  font-size: 13px;
  font-weight: 400;
  border-bottom: 2px dotted #dee1e1;
}

.overlay_blur {
  position: absolute;
  width: 90%;
  height: 62%;
  top: 76vh;
  left: 114px;
  right: 0;
  bottom: 0;
  background-color: rgb(219 219 219 / 73%);
  z-index: 2;
  cursor: pointer;
  border-radius: 10px;
}

.chart_section {
  border-radius: 10px !important;
  background-color: #ffffff;
  width: 100%;
  border: 1px solid #dee1e1;
}

.warning {
  align-items: center;
  flex-direction: column;
  display: flex;
  justify-content: center;
  min-height: 100%;
}

.head_label {
  font-size: 13px;
  color: #364141;
  font-weight: 600;
  margin-top: 20px;
}

.sub_head_label {
  font-size: 13px;
  color: #364141;
  font-weight: 400;
}

.warning img {
  width: 5%;
}

/* Chart Designs */
.chart_heading {
  border-bottom: 1px solid #dee1e1;
}

.label_head {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.label_sub_head {
  font-size: 13px;
  color: #364141;
  font-weight: 400;
}

.chart_table {
  border: 1px solid #dee1e1;
  /* margin: 10px; */
  border-radius: 10px !important;
}

.chart_table tr th {
  padding: 10px !important;
  font-size: 11px;
  font-weight: 400;
  color: #515e5f;
  border-bottom: 1px solid #dee1e1;
}

/* Assets Libary Css */

.assets_toggle {
  border: 1px solid #364141;
  border-radius: 10px;
  overflow: hidden;
}

.assets_toogle_btn {
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  border-right: 1px solid #364141;
  width: 100px;
  text-align: center;
  justify-content: center;
  font-size: 15px;
  font-weight: bold;
  padding: 6px 0;
  color: #364141;
}

.view_toogle_btn {
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  border-right: 1px solid #364141;
  width: 50px;
  text-align: center;
  justify-content: center;
  font-size: 15px;
  font-weight: bold;
  padding: 7px 0;
  color: #364141;
}

.active_view {
  background-color: #273333;
  color: #fff;
  padding: 2px 3px;
  border-radius: 3px;
}

.section_heading {
  font-size: 18px;
  font-weight: bold;
}

.folder_img {
  width: 30px;
}

.drag_box {
  border: 1px dashed #979797;
  border-radius: 15px;
  width: 100%;
  background-color: #f3f4f4;
}

.logo_drag_box {
  border: 1px dashed #979797;
  border-radius: 15px;
  /* width: 100%; */
  margin: 0 20px;
  background-color: #f3f4f4;
}

.brand_logo_img {
  width: 20%;
  max-height: 30px;
}

.drag_head_name {
  font-size: 18px;
  font-weight: bold;
  color: #515e5f;
}

.supported_file_text {
  font-size: 12px;
  font-weight: bold;
  color: #979797;
}

.browse_btn {
  background-color: #fff;
  border: 1.5px solid #d5d5d5;
  border-radius: 10px;
  margin-top: 10px;
  font-size: 12px;
  line-height: 19.5px;
}

.color_font_section {
  border: 1px dashed #979797;
  border-radius: 15px;
  overflow: hidden;
  width: 32%;
  display: flex;
  flex-direction: column;
}

.font_style {
  background-image: url("./Assets/color_grp.png");
  background-size: cover;
  text-align: center;
}

.userFonts {
  font-size: 30px;
}

.edit_font_head {
  font-size: 18px;
  color: #515e5f;
  font-weight: bold;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100%;
  background-color: #f3f4f4;
}

.logo_section {
  background-color: #f3f4f4;
  border: 1px dashed #979797;
  width: 32%;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.brand_img {
  width: 50px;
  margin-top: 10px;
  border-radius: 50px;
}

.brand_head {
  font-size: 12px;
  margin-top: 10px;
}

.sc-aXZVg {
  width: 100% !important;
}

.filter_section {
  background-color: transparent;
  border-top: 1px solid #dee1e1;
  border-bottom: 1px solid #dee1e1;
  padding: 5px;
}

.select_filter {
  border: 1px solid #b8bbc2;
  border-radius: 10px;
  padding: 5px;
  width: 100%;
  background-color: #fff;
  text-align: left;
  padding: 0 10px;
}

.filter_head {
  font-size: 12px;
  color: #515e5f;
}

.filter_selcted_option {
  font-size: 11px;
  color: #515e5f;
}

.option_list ul {
  margin: 0 !important;
  padding: 0 !important;
  list-style: none;
  z-index: 100;
  position: absolute;
  background-color: #fff;
  border-radius: 10px;
  width: 28%;
}

.option_list ul li {
  padding: 10px;
}

.current_month {
  border: 1.5px solid #364141;
  padding: 10px;
  margin: 0 10px;
  border-radius: 10px;
}

/* Account setting */
/* account setting  */

.acc-head {
  font-size: 32px;
  /* margin-left: 10vw; */
  font-weight: bold;
  margin-top: 20px;
  color: #3d3d3d;
  width: 80%;
  text-align: left;
  margin-bottom: 10px;
}

.acc-email {
  font-size: 12px;
  font-weight: bold;
  margin-left: 20px;
  margin-top: 10px;
}

.account-box {
  width: 80%;
  height: 24vh;
  border: 1px solid #b8b8b8;
  border-radius: 5px;
  background-color: #ffffff;
  /* box-shadow: 0 1px 4px 0 #00000094; */
  /* margin-left: 10vw;
  margin-top: 20px; */
}

.Acc-input-field {
  width: 95%;
  margin-left: 20px;
  margin-top: 10px;
  border: 1px solid #b8b8b8;
  border-radius: 5px;
  padding: 5px;
}

.changes_btn_section {
  align-items: end;
  width: 100%;
  justify-content: right;
}

.acc-btn1 {
  width: 100%;
  border: hidden;
  background: #273333;
  color: #fff;
  height: 40px;
  border-radius: 5px;
  font-size: 14px;
}

.acc-btn2 {
  width: 95%;
  padding: 10px;
  /* height: 40px; */
  border: hidden;
  background: #1a2fed;
  color: #fff;
  border-radius: 5px;
  font-size: 14px;
}

.pass-text {
  font-size: 12px;
  margin-left: 20px;
  margin-top: 5px;
}

.account-box2 {
  width: 80%;
  border: 1px solid #b8b8b8;
  background-color: #ffffff;
  border-radius: 5px;
  margin-top: 20px;
  /* box-shadow: 0 1px 4px 0 #00000094; */
}

.acc-dlt {
  border: 1px solid #b8b8b8;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  background: #f54747;
  margin-top: 30px;
  padding: 10px 40px;
  font-size: 14px;
  /* font-weight: bold; */
}

.delete-btn-box {
  justify-content: right;
  width: 80%;
}

.account-box3 {
  width: 80%;
  height: 6vh;
  /* border: 1px solid #273333; */
  /* margin-left: 10vw; */
  margin-top: 20px;
  background: #fff;
}

.acc-box3-text {
  font-size: 14px;
  font-weight: bold;
  margin-left: 20px;
  /* margin-top: 30px; */
}

.account-box4 {
  width: 80%;
  height: 9vh;
  /* border: 1px solid #273333; */
  /* margin-left: 10vw; */
  margin-top: 5px;
  background: #fff;
  margin-bottom: 10px;
}

.acc-box4-text {
  font-size: 12px;
  margin-left: 20px;
  margin-top: 10px;
}

/* group & social profile  */

.grp-head {
  font-size: 30px;
  font-weight: bold;
  color: #3d3d3d;
  margin-top: 30px;
  margin-left: 15px;
}

.grp-box1 {
  width: 97%;
  height: auto;
  padding: 15px 10px;
  margin-top: 5px;
  margin-left: 15px;
  background: #fff;
}

.grp-box1-text1 {
  font-size: 24px;
  font-weight: 600;
  color: #3d3d3d;
  margin-left: 20px;
  margin-top: 10px;
}

.grp-box1-text2 {
  font-size: 12px;
  margin-left: 20px;
  margin-top: 5px;
  color: #3d3d3d;
}

.grp-img {
  width: 13vw;
  margin-left: 20px;
  margin-top: 20px;
}

.grp-social {
  width: 50px;
  margin-left: 20px;
  margin-top: 20px;
}

.profile-text {
  text-align: center;
  color: #3d3d3d;
  font-size: 18px;
  font-weight: "500";
}

.profile-btn {
  background-color: #2079c3;
  color: #ffffff;
  font-size: 15px;
  border: none;
  border-radius: 8px;
  padding: 10px;
}

.member_count {
  font-size: 20px;
  font-weight: 500;
  color: #3d3d3d;
  padding: 10px 20px;
}

/* inbox message  */

.message-box {
  width: 96%;
  height: 100vh;
  border-top: 10px solid #d24e62;
  border-radius: 10px;
  margin-left: 20px;
}

.one-box {
  height: 97vh;
  background: #fff;
}

.reset_btn {
  color: #116daa;
  font-size: 13px;
  font-weight: bold;
}

.save_btn {
  border: 1.5px solid #364141;
  border-radius: 10px;
  padding: 8px 10px;
  color: #364141;
  font-size: 13px;
  font-weight: bold;
}

.mid-box {
  width: 100%;
  height: 50vh;
  border: 2px solid #dfe1e1;
  margin-top: 40px;
  overflow-y: scroll;
  background: #fff;
  border-radius: 12px;
}

.mid-link {
  font-size: 14px;
  text-decoration: underline;
  color: #4468b1;
  text-align: right;
  margin-top: 20px;
  padding-right: 40px;
}

.mid-sm-box1 {
  width: 100%;
  background: #e0ebfc;
  font-size: 12px;
  /* margin-top: 20px; */
  /* height: 25vh; */
  padding: 15px;
  margin: 0 10px;
  font-weight: bold;
  border-radius: 20px 20px 20px 0;
  /* margin-left: 70px; */
}

.mid-sm-box_send {
  width: 100%;
  background: #e0ebfc;
  font-size: 12px;
  /* margin-top: 20px; */
  /* height: 25vh; */
  padding: 15px;
  margin: 0 10px;
  font-weight: bold;
  border-radius: 20px 20px 0 20px;
}

.conver_img {
  width: 30px;
}

.mid-sm-box2 {
  width: 40%;
  margin: 0 10px;
  font-size: 12px;
  padding: 15px;
  font-weight: bold;
  border-radius: 20px;
  position: absolute;
  box-shadow: 0 1px 4px 0 #00000094;
  bottom: 0;
}

.message_options {
  border-bottom: 1px solid #d5d5d5;
  padding: 10px;
}

.message_head_box {
  font-size: 16px;
  color: #000000;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 10px;
  padding-left: 20px;
}

.message_send_btn {
  background-color: #4d94cf;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 10px;
}

.message_area {
  width: 100%;
  border: none;
  resize: none;
}

.box-div1 {
  width: 85%;
  background: #d24e62;
  margin-left: 20px;
  height: 10vh;
  border-radius: 12px;
  position: relative;
  top: 30px;
}

.circle-box {
  width: 35px;
  margin-top: 45px;
  margin-left: 20px;
}

.circle-box1 {
  width: 20px;
  margin-top: 60px;
  margin-left: 30px;
}

.upload_menu {
  border-radius: 10px;
  box-shadow: 1px 2px 8px 0px #706c6c;
  z-index: 10;
  position: absolute;
  background-color: #ffffff;
  animation: fadeIn 0.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.upload_menu ul {
  list-style: none;
  padding: 10px;
  width: 100%;
}

.upload_menu ul li {
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.transprentoverlay {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: transparent;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/* Social Names */
.uploaded_imgs {
  object-fit: contain;
  width: 100%;
  height: auto;
  margin: 0 10px;
}

.previewImgs {
  padding: 10px;
  width: 100%;
  /* flex: 0 0 50% !important; */
}

.previewImgs {
  flex-wrap: wrap;
}

.facebook_preview {
  border: 1px solid #dfe0e4;
  border-radius: 10px;
  /* padding: 10px; */
  width: 90%;
  animation: 0.5s ease-in 0s 1 toggleMenu;
}

@keyframes toggleMenu {
  0% {
    width: 90%;
    height: 100%;
  }

  100% {
    width: 90%;
    height: 100%;
  }
}

.fb_comments {
  width: 25px;
  height: 20px;
}

.likes_options {
  border-top: 1px solid #dfe0e4;
  padding: 10px;
}

.social_name {
  font-size: 15px;
  font-weight: 900;
}

.toggle_btn {
  width: 100%;
  border: none;
  background-color: transparent;
}

.userid_preview {
  font-size: 15px;
  font-weight: bold;
}

.date_preview {
  color: #9c9c9c;
  font-size: 14px;
}

.social_preview_box {
  border-top: 1px solid #dfe0e4;
  height: auto;
}

.preview_captions {
  font-size: 14px;
  line-height: 20px;
  word-break: break-word;
}

.storypreviewImgs {
  height: 40%;
}

.story_section {
  border: 1px solid #707070;
  border-radius: 10px;
  width: 100%;
  height: 68vh;
  box-shadow: 0px 0px 11px 4px #aca6a6;
}

.instagram_user_name {
  color: #262626 !important;
  font-weight: bold;
  margin: 0px 5px;
}

.insta_preview_date {
  font-size: 10px;
  margin: 5px 10px;
  color: rgb(81, 94, 95);
}

.avatar-skeleton {
  width: 100%;
}

.dummy_head {
  background-color: #fff;
  margin: 20px;
  padding-right: 18px;
  padding-bottom: 22px;
}

/* competitor css   */

.mode-box {
  border: 1.6px solid #2b87d3;
  width: 100%;
  height: 60px;
  background: #dcf2ff;
  border-radius: 5px;
  margin-top: 10px;
  padding: 10px;
}

.comp-box {
  border: 2px solid #dee1e1;
  width: 100%;
  height: 60px;
  /* background: #DCF2FF; */
  border-radius: 5px;
  margin-top: 10px;
  padding: 10px;
}

.com-div {
  flex-direction: row;
  display: flex;
  /* border: 1px solid #DEE1E1; */
}

.comp-box2 {
  border-bottom: 2px solid #dee1e1;
  border-top: 2px solid #dee1e1;
  border-right: 1px solid #dee1e1;
  width: 100%;
  height: 30vh;
  /* background: #DCF2FF; */
  /* border-radius: 5px; */
  margin-top: 2px;
  padding: 10px;
}

.comp-text1 {
  font-size: 13px;
}

.comp-text21 {
  font-size: 13px;
  margin-left: 15vw;
}

.comp-text22 {
  font-size: 13px;
  margin-left: 10vw;
}

.table-compbox {
  margin-top: 20px;
  border: 1px solid #dee1e1;
  width: 96%;
  margin-left: 20px;
  border-radius: 5px;
}

.comp-th1 {
  width: 90%;
  font-size: 11px;
  color: #515e5f;
  font-weight: bold;
  padding: 20px;
}

.comp-th2 {
  /* width: 7%; */
  font-size: 11px;
  color: #515e5f;
  font-weight: bold;
  padding: 20px;
}

.table-compbox th {
  border-bottom: 1px solid #dee1e1;
}

.table-compbox td {
  border-bottom: 1px solid #273333;
}

.comp-th3 {
  font-size: 14px;
  color: #273333;
  font-weight: bold;
  padding: 10px;
}

.comp-th4 {
  font-size: 13px;
  color: #273333;
  padding: 10px;
}

.comp-center {
  text-align: center;
  color: #515e5f;
  font-size: 12px;
  font-weight: bold;
  margin-top: 30px;
}

/* Profile section */
.comp-profile-tbl {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
}

.comp-profile-tbl thead tr {
  text-align: left;
  padding: 15px;
  font-size: 12px;
  border-bottom: 1px solid #dee1e1;
  color: #515e5f;
  text-decoration: underline;
}

.comp-profile-tbl thead tr th {
  border-bottom: 1px solid #000;
}

.comp-profile-tbl tbody tr {
  text-align: left;
  padding: 8px;
  font-size: 11px;
  border-bottom: 1px solid #dee1e1;
  color: #515e5f;
}

.pro-img {
  width: 14px;
}

.pro-tbl-name {
  font-size: 12px;
  font-weight: bold;
  color: #515e5f;
  margin-left: 10px;
}

.pro-tbl-name1 {
  font-size: 12px;
  font-weight: bold;
  color: #273333;
  width: 40%;
}

.report_dates {
  font-size: 11px !important;
  color: #515e5f;
  font-weight: 100;
}

.text-right {
  text-align: right;
}

.rate_incr {
  color: #067c7c;
  font-weight: 700;
}

.chart_section {
  margin-top: 20px;
}

.disabled {
  background-color: #c8c7c74a;
  border-radius: 10px;
}

.enable {
  cursor: pointer;
}

.update_btn {
  background-color: #2079c3;
  border: none;
  margin: 10px;
  border-radius: 10px;
  color: #fff;
}

.message_time {
  font-size: 6px;
}

.message_list {
  padding-left: 0px !important;
}

.message_list li {
  list-style: none;
  margin: 10px;
  font-weight: 700;
  cursor: pointer;
}

.user_profile_image {
  width: 40px;
  padding: 5px;
}

.username {
  font-size: 13px;
  color: #273333;
  margin-left: 10px;
}

.post_date {
  color: #364141;
  font-size: 11px;
}

.post_username {
  border-bottom: 1px solid #dee1e1;
}

.post_caption {
  font-size: 12px;
  color: #364141;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* Adjust based on line height */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: left;
  width: 100%;
  height: 95px; /* Fixed height */
  line-height: 19px; /* Adjust based on font size */
  margin-bottom: 10px;
}

.post_img {
  width: 100%;
  padding: 10px;
  padding-top: 30px;
  height: 200px;
}

.post_details tbody tr {
  margin: 10px;
}

.post_details tbody tr td {
  font-size: 13px;
  border-bottom: 2px solid #dee1e1;
}

.post_details tbody tr td:first-child {
  text-decoration: underline;
}

.uploaded_posts {
  background-color: #fff;
  padding: 20px;
}

.uploaded_assets {
  width: 100%;
  border-radius: 20px;
  box-shadow: 0px 0px 6px 4px #bdb5b5;
  margin: 10px;
  object-fit: contain;
  height: 350px;
  position: relative;
}

.add_competitor {
  background-color: #17405f;
  border-radius: 10px;
  padding: 10px 15px;
  font-size: 13px;
  color: #fff;
  border: none;
  font-weight: bold;
}

/* Social Group */

.grp-head {
  font-size: 20px;
  font-weight: bold;
  color: #3d3d3d;
  margin-top: 30px;
  /* margin-left: 15px; */
}

.grp-btn {
  border: hidden;
  margin-top: 30px;
  color: #1050aa;
  font-size: 16px;
  font-weight: bold;
  background: transparent;
}

.grphead-btn {
  display: flex;
  flex-direction: row;
}

.grp-vector {
  width: 20px;
  margin-top: 27px;
}

.grp-vector1 {
  width: 24px;
  margin-top: 35px;
}

/* Connect pop up */

.grp-modal-head {
  text-align: left;
  font-size: 18px;
  font-weight: bold;
}

.modalgrp-div {
  display: flex;
  flex-direction: row;
}

.modalgrp-div2 {
  display: flex;
  flex-direction: row;
}

.modal-card {
  width: 24%;
  height: 20vh;
  margin-left: 10px;
  border: 1px solid #d5d5d5;
  border-radius: 12px;
  margin-top: 20px;
  padding: 0 10px;
}

.ant-modal {
  width: 50% !important;
}

.modal-card2 {
  width: 29%;
  height: 20vh;
  margin-left: 10px;
  border: 1px solid #d5d5d5;
  border-radius: 12px;
  margin-top: 20px;
}

.modal-connect {
  width: 100%;
  font-size: 12px;
  border-color: 1px solid #75afe8;
}

.modal-img {
  width: 30px;
}

.card-img-div {
  text-align: center;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.modal-text {
  font-size: 12px;
  font-weight: bold;
  margin-top: 10px;
}

.grp-box1 {
  width: 97%;
  height: auto;
  padding: 15px 10px;
  margin-top: 5px;
  margin-left: 15px;
  background: #fff;
}

.grp-box1-text1 {
  font-size: 24px;
  font-weight: 600;
  color: #3d3d3d;
  margin-left: 20px;
  margin-top: 10px;
}

.grp-box1-text2 {
  font-size: 12px;
  margin-left: 20px;
  margin-top: 5px;
  color: #3d3d3d;
}

.grp-img {
  width: 13vw;
  margin-left: 20px;
  margin-top: 20px;
}

.grp-social {
  width: 50px;
  margin-left: 20px;
  margin-top: 20px;
}

.profile_img {
  width: 30px;
  border-radius: 50%;
}

.profile_img_post {
  width: 10%;
  border-radius: 50%;
}

.profile_name {
  font-size: 15px;
  font-weight: bold;
}

.profile_list {
  list-style: none;
}

.profile_list li {
  margin: 10px 0;
  border-bottom: 1px solid #d5d5d5;
  padding: 10px;
}

.profile_action_btn {
  background-color: transparent;
  border: none;
}

.warning_msg {
  font-weight: bolder;
  color: #d40c0c;
  z-index: 100000;
}

.profile_list_btn {
  background-color: transparent;
  border: none;
}

.save_btn {
  border: none;
  background-color: #0a66c2;
  color: #fff;
}

.profile_picture {
  border-radius: 50%;
}

.yt_status {
  width: 100%;
}

.create_folder_btn {
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #000;
}

.create_folder_modal_btn {
  border-radius: 10px;
  background-color: #2242cf;
  border: 1px solid #000;
  color: #fff;
}

.folder_section {
  border: 1px solid #000;
  padding: 10px;
  width: 10%;
  text-align: center;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 3px 4px 10px 0px #525151;
  cursor: pointer;
}

.Selected_folder {
  border: 1px solid #2c19d1;
  padding: 10px;
  width: 10%;
  text-align: center;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 3px 4px 10px 0px #525151;
  cursor: pointer;
}

.root_folder {
  padding: 10px;
  width: 5%;
  text-align: center;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 3px 4px 10px 0px #525151;
  cursor: pointer;
}

.share_btn {
  border: 1px solid #000;
  padding: 8px 10px;
  border-radius: 50%;
  /* position: absolute; */
  background-color: #fff;
}

.assets_div {
  position: relative;
}

.video_preview {
  width: 100%;
  height: 100vh;
}

.sub_folder_btn {
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #000;
}

.Youtube_video_preview {
  width: 100%;
}

.Youtube_title_preview {
  font-family: "Roboto", "Arial", sans-serif;
  font-size: 2rem;
  line-height: 2.8rem;
  font-weight: 700;
}

.Youtube_channel_icon_preview {
  width: 40px;
}

.Youtube_subscribe_btn_preview {
  border-radius: 40px;
  padding: 10px 15px;
  border: none;
  margin-left: 10px;
  background-color: #ff0000;
  color: #fff;
  font-weight: bold;
}

.yt_like_preview {
  padding: 10px 20px;
  border: none;
  border-radius: 20px 0 0 20px;
  background-color: #8e8e8e79;
  color: #fff;
}

.yt_dislike_preview {
  border: none;
  border-radius: 0 20px 20px 0;
  padding: 10px;
  background-color: #8e8e8e79;
  color: #fff;
  border-left: 1px solid #fff;
}

.yt_share_preview {
  border: none;
  border-radius: 20px;
  margin-left: 2px;
  padding: 10px;
  background-color: #8e8e8e79;
  color: #fff;
  border-left: 1px solid #fff;
}

.pint-input {
  width: 95%;
  margin-left: 28px;
  height: 40px;
  border: 1.6px solid gray;
  border-radius: 5px;
  font-size: 14px;
  padding-left: 10px;
  margin-top: 5px;
}

.pint-label {
  margin-left: 28px;
}

.board_list_img {
  width: 5%;
  border-radius: 50%;
  object-fit: contain;
}

.pint_board_list {
  list-style: none;
}

.pint_board_list li {
  margin: 10px;
  cursor: pointer;
}

.pint_board_list_menu {
  width: 95%;
  position: absolute;
  background-color: #fff;
  box-shadow: 0px -1px 10px 5px #a3a3a3;
  height: 200px;
  overflow-y: scroll;
  z-index: 1000;
  margin-top: 10px;
  border-radius: 10px;
  padding: 10px;
  margin-left: 25px;
  /* top: 20px; */
  transition: ease-out 1s all;
}

.pint_board_list_menu::-webkit-scrollbar {
  width: 5px;
}

.pint_board_list_menu::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.pint_board_list_menu::-webkit-scrollbar-thumb {
  background: #7d7d7d;
  border-radius: 10px;
}

/* Handle on hover */
.pint_board_list_menu::-webkit-scrollbar-thumb:hover {
  background: #7d7d7d;
}

.create_board_btn {
  border: none;
  background-color: #2079c3;
  border-radius: 10px;
  padding: 10px 15px;
  color: white;
}

.text_right {
  text-align: right !important;
  color: #116daa !important;
}

.preview_imgs {
  width: 70%;
  height: 100%;
  border-radius: 10px;
  /* position: relative; */
}

.image_preview_Section {
  position: relative;
}

.cross_photo {
  position: absolute;
  background-color: #92929293;
  color: #fff;
  padding: 5px;
  z-index: 1;
  border-radius: 20px;
}

.upload_img_list {
  display: flex;
  align-items: center;
}

.box_size {
  /* width: 23%; */
  width: 337px;
  height: 528px;
  padding: 8px !important;
}

.assets_menu {
  position: absolute;
  top: 20px;
  right: 10px;
}

.assets_menu_list {
  background-color: #fff;
  position: absolute;
  right: 10px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 3px 8px 3px #000;
}

.assets_menu_list_section {
  list-style: none;
  padding-left: 10px;
  margin-bottom: 0;
}

.assets_menu_list_section li {
  padding: 8px;
}

/* invite modal css */

.invite-head {
  font-size: 20px;
  font-weight: bold;
}

.grp-input {
  border: 1.5px solid grey;
  margin-top: 15px;
  border-radius: 5px;
  overflow: hidden;
  width: 17vw;
  padding-left: 10px;
}

.ant-modal {
  width: 70% !important;
}

/* from existing css  */

.exist-div1 {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
}

.exist-pic {
  width: 50px;
}

.exist-div2 {
  margin-left: 20px;
}

.exist-text {
  font-weight: bold;
}

.from-btn {
  color: #fff;
  font-size: 16px;
  background: #4d94cf;
  border: hidden;
  border-radius: 5px;
  width: 13%;
  height: 40px;
}

.from-div {
  text-align: right;
  /* margin-top: 20vw ; */
}

.Group_folder {
  background-color: transparent;
  color: #353535;
  padding: 10px;
  border: 1px solid #353535;
  margin: 0 10px;
  border-radius: 10px;
}

.Group_folder_active {
  background-color: #353535;
  color: #fff;
  padding: 10px;
  margin: 0 10px;
  border-radius: 10px;
}

.post_imgs {
  width: 100%;
  object-fit: cover;
  border-radius: 6px;
  height: 226px;
}

.pagenation_section {
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
}

.pages {
  margin: 8px;
  border: 1px solid #00000066;
  background-color: #cecece;
  cursor: pointer;
}

.active-page {
  background-color: #858585;
}

.Group_dropdown {
  padding: 10px;
  margin-top: 5px;
  border-radius: 10px;
  border: 1px solid #000;
}

.Group_label {
  font-size: 15px;
  font-weight: 700;
}

.select_page_head {
  text-align: center;
}

.pages_list_section {
  height: 50vh;
  max-height: 50vh;
  overflow-y: scroll;
  padding: 0 10px;
}

.Next_page {
  background-color: rgb(32, 91, 195);
  border: none;
  padding: 10px 50px;
  color: #fff;
  border-radius: 10px;
}

.disabled_btn {
  background-color: rgb(139, 141, 145);
  border: none;
  padding: 10px 50px;
  color: #fff;
  border-radius: 10px;
}

.post_performance_tabs {
  border: none;
  padding: 10px;
  background-color: #fff;
  font-size: 13px;
  font-weight: 700;
  color: #676767;
}

.post_performance_tabs_active {
  border-bottom: 2px solid #2079c3;
  color: #000;
}

.List_dropdown {
  position: absolute;
  background-color: #fff;
  width: 46%;
  margin-top: 55px;
  box-shadow: 1px -1px 10px 0px #000;
  border-radius: 10px;
  padding: 10px;
  z-index: 1000;
  height: 34%;
  overflow-y: scroll;
}

.List_dropdown ul {
  padding-left: 0px !important;
}

.List_dropdown li {
  list-style: none;
  margin: 15px 0;
}

.list_btn {
  background-color: #fff;
  border: 0.75px solid #dee1e1;

  padding: 6px 2px;
  border-radius: 6px;
}

.platform_name {
  border: 0.35px solid #dee1e1;
  background-color: #ffffff;
  margin: 0 5px;
  padding: 2px;
  border-radius: 6px;
  align-items: center;
  gap: 3px;
  padding: 2px 5px;
}

.more_txt {
  font-size: 13px;
  color: #979797;
}

.pages_list_view {
  border: 1px solid rgb(222, 225, 225);
  padding: 10px;
  border-radius: 10px;
}

.platform_field_names {
  font-size: 12px;
  width: 100%;
  padding: 10px;
  border-radius: 10px 10px 0 0;
  border: 1px solid #e0e0e0;
  font-weight: 900;
}

.options_table tr td input[type="text"],
textarea {
  border-radius: 10px;
  border: 1px solid #000;
  padding: 10px;
}

.option_section {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
}

.pinterest_cation {
  margin-left: 20px;
  font-size: 14px;
  font-weight: 700;
}

.piterest_modal_section {
  margin: 20px;
  border: 1px solid #bdbdbd;
  border-radius: 10px;
}

.pinterest_imgs {
  border-radius: 10px !important;
}

.group_btn {
  height: max-content;
  border: none;
  background-color: transparent;
  margin-bottom: 30px;
  color: #198bd7;
}

.group_sideModal {
  background-color: #fff;
  width: 40%;
  position: fixed;
  height: 100vh;
  float: right;
  /* left: 105px; */
  left: 8%;
  z-index: 10000;
  top: 0;
  animation-name: movereverse;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-direction: normal;
  animation-timing-function: ease-in;
  overflow: hidden;
  box-shadow: rgb(110, 110, 110) 3px 2px 6px 0px;
}

@keyframes movereverse {
  0% {
    left: 0;
  }

  50% {
  }
}

.group_modal_head {
  border-bottom: 1px solid #bebebe;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.grp_modal_add_btn {
  font-size: 15px;
  text-decoration: none;
  font-weight: 700;
  color: #116daa;
}

.group_list {
  padding-left: 0px;
  padding: 0px 20px;
}

.group_list li {
  list-style: none;
}

.group_list li button {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  padding: 10px;
  text-align: left;
  border: 1px solid #d6d6d6;
  background-color: transparent;
  border-radius: 10px;
}

.group_list_active {
  border: 1px solid #000 !important;
  border-radius: 10px;
}

.filter_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: transparent;
  border: none;
}

.filter_head span {
  font-family: "Roboto";
  font-size: 13px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #273333;
}

.platform_name_header {
  background-color: initial;
  border: none;
  font-size: 11px;
  line-height: 19px;
  width: 100%;
  margin-left: 10px;
}

.filter_list_section {
  background-color: #fff;
  position: absolute;
  top: 15px;
  height: 50vh;
  z-index: 1000;
  width: 25%;
  box-shadow: 1px 1px 5px 0px #5c5a5a;
  border-radius: 10px;
  overflow-y: scroll;
}

.filter_list_head {
  border-bottom: 1px solid #d3d3d3;
  font-weight: 600;
  font-size: 12px;
}

.close_btn {
  background-color: transparent;
  border: none;
  color: #0a66c2;
}

.Filter_list {
  padding-left: 0 !important;
}

.Filter_list li {
  list-style: none;
}

.Filter_list li div {
  border: none;
}

.competitor_search {
  position: absolute;
  right: 20px;
  margin: 10px;
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 2px 3px 5px 2px #666161;
}

.competitor_Search_btn {
  padding: 5px;
  background-color: #fff;
  border: none;
}

.social_platform_logo_btn {
  background-color: transparent;
  border: none;
}

.active_social_platform_logo_btn {
  border-radius: 10px;
  border: 1px solid #000;
}

.brand_icons {
  width: 30px;
  border-radius: 50%;
  margin-right: 5px;
}

.search_brands {
  list-style: none;
  padding-left: 0 !important;
}

.search_brands li {
  margin: 10px 0;
}

.view_image_btn {
  border: 0;
  background-color: transparent;
}

.preview_img {
  height: 70%;
  width: 100%;
  /* border-radius: 10px; */
}

.preview_img_modal .ant-modal-content {
  width: fit-content !important;
  padding: 0 !important;
  overflow: hidden;
}

.preview_img_modal {
  width: 520px !important;
}

.loader_section {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.posts_icons {
  border: 1px solid #000;
  padding: 5px;
  border-radius: 10px;
  width: fit-content;
  margin-top: 5px;
  margin: 0 5px;
}

.posts_icons i {
  margin-right: 5px;
}

.filter_profile_img {
  width: 30px;
  border-radius: 50%;
}

.comment_post_img {
  width: 200px;
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
}

.tag_modal {
  position: fixed;
  border-radius: 10px;
  box-shadow: 0px 0px 8px 1px #000;
  background-color: #fff;
  right: 50px;
  width: 30%;
  overflow: hidden;
  z-index: 1000000;
}

.tag_header {
  border-bottom: 1.5px solid #c2c1c17b;
  padding: 20px;
}

.tag_remove_btn {
  border: none;
  background-color: transparent;
  color: #198bd7;
  text-decoration: underline;
}

.tag_modal_body {
  padding: 20px;
}

.tag_search_fields {
  border-radius: 10px;
  border: 1px solid #000;
  padding: 5px 10px;
}

.tag_search_fields:focus {
  outline: none;
  border-color: #8290ec;
  box-shadow: inset 0 1px 4px #475df0, 0 0 10px 2px #475df0;
}

.tag_footer {
  padding: 20px;
  border-top: 1.5px solid #c2c1c17b;
}

.add_tag_btn {
  border: none;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: underline;
  color: #198bd7;
}

#tag_overlay {
  position: fixed;
  width: 100%;
  /* Full width (cover the whole page) */
  height: 100%;
  /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  /* Black background with opacity */
  z-index: 1000;
  /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  /* Add a pointer on hover */
}

.Tag_filter {
  border: 1px solid #b8bbc2;
  border-radius: 10px;
  text-align: left;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.filter_name {
  font-size: 12px;
  color: #515e5f;
}

.filter_selected_tags {
  font-size: 11px;
  color: #515e5f;
}

.filter_list_option {
  position: absolute;
  background-color: #fff;
  width: 22%;
  border-radius: 10px;
  margin-top: 5px;
  box-shadow: 0px 0px 8px 1px #000;
}

.search_btn {
  border: 1px solid #9a9898;
  background-color: transparent;
  border-radius: 10px;
}

.Filter_list {
  /* height: 120px; */
  /* max-height: 120px; */
  /* overflow-y: scroll; */
  height: 82%;
  overflow-y: auto;
}

.Filter_list::-webkit-scrollbar {
  width: 5px;
}

.Filter_list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.Filter_list::-webkit-scrollbar-thumb {
  background: #7d7d7d;
  border-radius: 10px;
}

/* Handle on hover */
.Filter_list::-webkit-scrollbar-thumb:hover {
  background: #7d7d7d;
}

.comment-reply_header {
  background-color: rgb(243, 244, 244);
  padding: 10px;
  border: 1px solid rgb(222, 225, 225);
  border-radius: 10px 10px 0 0;
}

.comment_date {
  color: rgb(81, 94, 95);
  font-weight: 400;
  font-size: 12px;
}

.comment_reply_section {
  border: 1px solid rgb(222, 225, 225);
  border-radius: 10px;
}

.comment_post {
  padding: 16px 16px 16px 64px;
}

.comment_posts_img {
  width: 100%;
  border-radius: 10px;
  height: 250px;
  object-fit: contain;
}

.comment_user {
  width: 30px;
}

.user_comment {
  background-color: #f0f2f5;
  padding: 5px 10px;
  border-radius: 10px;
  margin-left: 5px;
}

.reply_section {
  margin-left: 100px;
}

.comment_reply_textarea {
  resize: none;
  border: none;
  background-color: #f0f2f5;
}

.post_header_img_comment {
  width: 30px;
  border-radius: 50% !important;
}

.reply_btn {
  background-color: transparent;
  border: none;
}

.list_menu {
  position: absolute;
  top: 50px;
  z-index: 1000;
  background-color: #fff;
  border: none;
  border-radius: 10px;
  box-shadow: 0px 0px 6px 2px #5b5b5b;
  margin-top: 8px;
  width: 200px;
}

.list_menu ul {
  margin-top: 10px;
  padding: 10px;
  list-style: none;
}

.list_menu ul li button {
  background-color: transparent;
  border: none;
}

.Group_name_filter {
  font-size: 12px;
  color: #515e5f;
}

.import_btn {
  background-color: #2079c3;
  color: #fff;
  font-size: 12px;
  font-family: "Roboto";
  border: none;
  border-radius: 8px;
  padding: 10px;
}

.download_file {
  border: none;
  background-color: transparent;
  color: #898989;
}

.brand_onboarding_box {
  display: flex;
  justify-content: center;
}

.brand_box {
  border: 1px solid #d9d9d9;
  width: 40%;
  justify-content: "center";
  margin: 20px 0px;
  border-radius: 10px;
  box-shadow: 0px 1px 4px 3px #d1d1d1;
  padding: 10px;
}

.brand_head {
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  color: #171717;
  font-family: "Inter";
}

.sub_head {
  font-size: 14px;
  line-height: 21px;
  color: #797979;
  font-family: "Inter";
}

.input_label {
  font-size: 14px;
  line-height: 21px;
  font-weight: bold;
  font-family: "Inter";
  margin: 10px 0;
  color: #273333;
}

.form_input {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 5px;
  background-color: transparent;
}

.form_input_phone {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 5px;
}

.form_input_phone input {
  border: none;
}

.downarrow_icon {
  background-color: #efefef;
  padding: 10px;
  border-radius: 5px;
}

.downarrow_icon i {
  color: #979797;
}

.drop_list {
  position: absolute;
  z-index: 100;
  height: 150px;
  max-height: 150px;
  overflow-y: scroll;
  background-color: #fff;
  width: 36vw;
  border-radius: 10px;
  box-shadow: 0px 1px 4px 3px #d1d1d1;
}

.drop_list ul {
  list-style: none;
}

.drop_list ul li {
  margin: 5px;
}

.drop_list ul li button {
  border: none;
  width: 100%;
  background-color: transparent;
  text-align: left;
}

.logo_section {
  background-color: #00000075;
  width: 76px;
  height: 76px;
  display: flex;
  justify-content: center;
  position: relative;
}

.cross_btn {
  position: absolute;
  top: -5px;
  right: -7px;
  background-color: transparent;
  border: none;
}

.input_sub_label {
  font-size: 13px;
  line-height: 21px;
  font-family: "Inter";
  /* margin: 10px 0; */
  color: #797979;
}

.color_box {
  width: 30px !important;
  height: 30px !important;
  max-width: 20px;
  max-height: 20px;
  border: 1px solid #1717172e;
  border-radius: 5px;
}

.color_label {
  font-size: 13px;
  line-height: 21px;
  font-family: "Inter";
  /* margin: 10px 0; */
  color: #000000;
  cursor: pointer;
}

.color_section {
  width: 90%;
}

.color_boxs {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 8px 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.2s ease;
}

.color_boxs:hover {
  border-color: #2079c3;
}

.color_box {
  width: 24px !important;
  height: 24px !important;
  max-width: 24px;
  max-height: 24px;
  border: 1px solid #1717172e;
  border-radius: 6px;
}

.color_label {
  font-size: 14px;
  line-height: 21px;
  font-family: "Inter", sans-serif;
  color: #273333;
  margin-left: 8px;
  cursor: pointer;
}

.color_picker {
  position: absolute;
  top: 45px;
  z-index: 100;
  background: white;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
}

.logo_section {
  background-color: rgba(0, 0, 0, 0.05);
  width: 76px;
  height: 76px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 8px;
  transition: all 0.2s ease;
}

.logo_section:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.add_palette_btn {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  border: 1px solid #d9d9d9;
  background-color: #ffffff;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.add_palette_btn:hover {
  border-color: #2079c3;
  background-color: #f8f9fa;
}

.palette_container {
  position: absolute;
  width: 280px;
  z-index: 100;
  margin-top: 8px;
}

.colorpalette_list {
  list-style: none;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
}

/* Add these new styles */
.font_select_btn {
  min-height: 42px; /* Fixed height for the button */
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 8px 12px;
  width: 100%;
  transition: all 0.2s ease;
}

.font_select_btn:hover {
  border-color: #2079c3;
  background-color: #f8f9fa;
}

.font_select_btn label {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.font_head_label {
  color: #273333;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
}

/* Update existing styles */
.input_label {
  font-size: 14px;
  line-height: 21px;
  font-weight: bold;
  font-family: "Inter";
  margin: 10px 0;
  color: #273333;
}

.input_sub_label {
  font-size: 13px;
  line-height: 21px;
  font-family: "Inter";
  color: #797979;
}

.week-view-table {
  width: 100%;
  border-collapse: collapse;
  height: calc(
    100vh - 250px
  ); /* Adjust the value based on your header height */
}

.week-view-table td {
  border: 1px solid #dee1e1;
  vertical-align: top;
  padding: 10px;
  height: 100%;
}

.week-view-table .weeks-day {
  background-color: #fff;
  position: relative;
  height: 100%; /* Override the fixed height from the month view */
  width: auto; /* Override the fixed width from the month view */
}

.week-view-table .current {
  border-width: 4px 2px 2px 2px;
  border-style: solid;
  border-color: #2b87d3;
}

.week-view-table .action_task {
  position: absolute;
  bottom: 10px;
  width: calc(100% - 20px);
  display: flex;
  justify-content: space-between;
}

/* Add a container class for the calendar section */
.days_section {
  height: calc(100vh - 200px); /* Adjust based on your layout */
  overflow: auto;
}

.week-action-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin: 10px 0;
}

.schedule-post-btn {
  background-color: #2079c3;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
  width: 80%;
}

.schedule-post-btn:hover {
  background-color: #1a6098;
}

.draft-btn {
  background-color: white;
  color: #2079c3;
  border: 1px solid #2079c3;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.draft-btn:hover {
  background-color: #f0f7fc;
}

.draft-btn i {
  margin-right: 8px;
}

/* Update week view table styles */
.week-view-table .weeks-day {
  background-color: #fff;
  position: relative;
  height: 100%;
  width: auto;
  padding: 15px;
}

/* Week View Calendar Styles */
.week-view-table {
  width: 100%;
  border-collapse: collapse;
}

.weeks-day {
  width: 14.28%; /* Equal width for all days (100% / 7) */
  padding: 1rem;
  vertical-align: top;
  min-height: 200px;
  border: 1px solid #dee1e1;
  background-color: #ffffff;
}

.weeks-day.past-date {
  background-color: #f5f5f5;
}

.weeks-day.current {
  background-color: #f0f7ff;
}

.week-action-buttons {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;
}

.schedule-post-btn,
.draft-btn {
  width: 100%;
  padding: 0.75rem;
  border-radius: 0.25rem;
  border: none;
  cursor: pointer;
  font-size: 0.875rem;
}

.schedule-post-btn {
  background-color: #1890ff;
  color: white;
}

.draft-btn {
  background-color: #f0f0f0;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
}

.past-date-placeholder {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.past-date-placeholder .schedule-post-btn.disabled,
.past-date-placeholder .draft-btn.disabled {
  cursor: not-allowed;
  opacity: 0.7;
  background-color: #e5e5e5;
  color: #999;
}

.weeks-day .d-flex {
  justify-content: space-between;
  align-items: center;
}

/* Date Header Styles in Week View */
.date-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid #eee;
  width: 100%;
}

.date-number {
  font-size: 1.25rem;
  font-weight: 500;
  color: #333;
  padding-left: 0.5rem;
}

.date-day {
  font-size: 0.875rem;
  color: #666;
  padding-right: 0.5rem;
}

/* Adjust spacing for the entire week day cell */
.weeks-day {
  padding: 1rem;
}

.branding_conatiner {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.subscription-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40vh;
  background: linear-gradient(to right, #6a11cb, #2575fc);
  color: white;
  text-align: center;
  font-family: Arial, sans-serif;
}

h1 {
  font-size: 2rem;
  margin-bottom: 10px;
}

p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.buy-button {
  background-color: #ff6b6b;
  color: white;
  font-size: 1.2rem;
  padding: 12px 24px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

.buy-button:hover {
  background-color: #ff4757;
}
